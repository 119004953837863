export type StateColors = Readonly<{
	normal: string;
	hover: string;
	active: string;
}>;

export type InteractiveStateColorNames = Exclude<keyof StateColors, 'normal'>;

export const categoryKeys = ['text', 'positive', 'negative'] as const;

export const positiveUnmutedTextKeys = [
	'primary',
	'secondary',
	'normal',
] as const;

export const positiveTextKeys = [...positiveUnmutedTextKeys, 'muted'] as const;

export const negativeTextKeys = [
	'primaryContrast',
	'secondaryContrast',
	'normalContrast',
	'mutedContrast',
] as const;

export const positiveKeys = [
	'primary',
	'secondary',
	'normal',
	'muted',
] as const;

export const positiveKeysToNegativeTextKeys = {
	primary: 'primaryContrast',
	secondary: 'secondaryContrast',
	normal: 'normalContrast',
	muted: 'mutedContrast',
} as const;

export const negativeKeys = ['primary', 'secondary', 'normal'] as const;

export type ColorTheme = Readonly<{
	name: string;
	text: Readonly<{
		primary: StateColors;
		secondary: StateColors;
		normal: StateColors;
		muted: StateColors;
		primaryContrast: StateColors;
		secondaryContrast: StateColors;
		normalContrast: StateColors;
		mutedContrast: StateColors;
	}>;
	positive: Readonly<{
		primary: StateColors;
		secondary: StateColors;
		normal: StateColors;
		muted: StateColors;
	}>;
	negative: Readonly<{
		primary: StateColors;
		secondary: StateColors;
		normal: StateColors;
	}>;
}>;

export type ThemedColor<Category extends keyof ColorTheme> = [
	Category,
	keyof ColorTheme[Category]
];

export type TextThemedColor = ThemedColor<'text'>;
export type PositiveThemedColor = ThemedColor<'positive'>;
export type NegativeThemedColor = ThemedColor<'negative'>;

export type ShapeThemedColor = PositiveThemedColor | NegativeThemedColor;
export type AnyThemedColor =
	| TextThemedColor
	| PositiveThemedColor
	| NegativeThemedColor;

export type TextColor = TextThemedColor | string;
export type PositiveColor = PositiveThemedColor | string;
export type NegativeColor = NegativeThemedColor | string;
export type AnyColor = AnyThemedColor | string;
export type ShapeColor = ShapeThemedColor | string;

export type OPColorTheme = {
	project: 'OP';
} & ColorTheme;

export type BGOVColorTheme = {
	project: 'BGOV';
} & ColorTheme;

export type GlobalColorTheme = {
	project: 'global';
} & ColorTheme;
