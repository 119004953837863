import curry from './curry';
import { parseUrl } from './link';
import { get } from './immutableHelpersWithArgs';
import { AnyImmutableJsMap } from '../types/immutableJs';

const replacer = curry(
	(data: Record<string, string | number>, _match: string, name: string) =>
		data?.[name] || ''
);

export const interpolateString = (
	string = '',
	data: Record<string, string | number>
) => string.replace(/\$\{([A-z0-9]*)\}/g, replacer(data));

//Replace tags like ${title} to the proper values
export const getInterpolatedLink = (
	href: string,
	title: string,
	location: AnyImmutableJsMap,
	description: string
) =>
	interpolateString(href, {
		title: encodeURIComponent(title || ' '),
		url: encodeURIComponent(parseUrl(get('href')(location)).toString() || ' '),
		pageSummary: encodeURIComponent(description || ' '),
	});
