import { defaultTheme } from './defaultTheme';

const theme = Object.assign({}, defaultTheme, {
	header: {
		heading: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	},
});

export default theme;
